import * as React from 'react';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import { ExploreContainer } from './container/ExploreContainer';
import { NavBar } from './components/NavBar';
import { WelcomeContainer } from './container/WelcomeContainer';

import 'react-spring-bottom-sheet/dist/style.css';


function App() {

  const [locationFeature, setLocationFeature] = React.useState(undefined);

  const handleSetLocationFeature = feature => {
    setLocationFeature(feature);
  };

  const PUBLIC_URL = process.env.PUBLIC_URL || '';

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route 
            path={`${PUBLIC_URL}/`} 
            element={
              <WelcomeContainer 
                handleLocationFeature={handleSetLocationFeature}
              />
            } 
          />
          <Route 
            path={`${PUBLIC_URL}/explore`}
            element={
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ExploreContainer 
                  locationFeature={locationFeature} 
                  handleLocationFeature={handleSetLocationFeature} 
                />
              </LocalizationProvider>
            } 
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
