import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GeocoderInput } from '../components/GeocoderInput';
import { Footer } from '../components/Footer';
import { useResponsive } from '../hooks/useResponsive';

import background from '../img/test_promo.png';

const PUBLIC_URL = process.env.PUBLIC_URL || '';

export const WelcomeContainer = ({handleLocationFeature}) => {
  const {isDesktop, isMobile} = useResponsive();
  const publicUrl = path => {
    return `${PUBLIC_URL}${path}`;
  };
  const style = {
    container: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
    }, 
    imageContainer: {
      display: 'flex',
      width: '90%',
      height: '100%',
      minHeight: isMobile ? 'calc(100vh - 9.7em)' : 'calc(100vh - 10.2em)',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      alignItems: 'center',
      justifyContent: isDesktop ? null : 'center'
    },
    geocoderContainer: {
      display: 'flex',
      minWidth: 300,
      width: '90%',
      borderRadius: '1em',
      backgroundColor: '#FFFFFF'
    },
    presentContainer: {
      display: 'flex',
      borderRadius: '1em',
      minHeight: 400,
      alignItems: 'center',
      backgroundColor: 'hsla(0,0%,100%,.9)',
      transform: isDesktop ? 'translateX(20%)' : null,
      width: isDesktop ? '33%' : '80%',
      padding: '2em'
    }
  };
  const navigate = useNavigate();
  const handleLocationAndChangeRoute = (feature) => {
    if(feature) {
      handleLocationFeature(feature);
      navigate(publicUrl('/explore'));
    }
  };
  return (
    <Box sx={style.container}>
      <Box sx={style.imageContainer}>
        <Stack sx={style.presentContainer} direction='column' spacing={2}>
          <Box sx={{display: 'flex', justifyContent: 'center', width: '90%%', textAlign: 'center'}}>
            <Typography sx={{fontSize: isMobile ? '1.3em' : '1.6em', fontWeight: 700}}>
                            Baromètre des données piétonnes dans OSM
            </Typography>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center', textAlign: 'center', width: '90%%'}}>
            <Typography sx={{fontSize: isMobile ? '1em' : '1.2em', fontWeight: 500}}>
                            Saisissez le nom d&apos;une ville et découvrez à quel point ses données piétonnes sont décrites dans OSM<br/>
            </Typography>              
          </Box>
          <Box sx={style.geocoderContainer}>
            <GeocoderInput setLocationFeature={handleLocationAndChangeRoute}/>
          </Box>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};