import React from 'react';

import { Box, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useResponsive } from '../hooks/useResponsive';


export const CheckboxLayer = ({id, value, label, color, handleLayer, explain, type='line'}) => {
  const {isDesktop} = useResponsive();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', minWidth: isDesktop ? '15em' : '10em', width: isDesktop ? 'max-content' : '100%', maxWidth: '20em'}}>
        <FormControlLabel
          sx={{display: 'flex', minWidth: isDesktop ? '5em' : '3em', width: isDesktop ? '20em' : '70%'}}
          value={id}
          control={<Checkbox checked={value} onChange={evt => handleLayer(evt.target.value)} />}
          label={label}
          labelPlacement="end"
        />
        <Tooltip open={open} arrow title={<Typography fontSize={isDesktop ? null : '1.4em'}>{explain}</Typography>} placement='bottom' onClose={handleClose}>
          <IconButton size='small' onClick={handleOpen}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{display: 'flex', width: 100, height: type === 'point' ? '1em' : '.5em', alignSelf: 'center'}}>
        <Box sx={{backgroundColor: color, width: type === 'point' ? '1em' : '100%', borderRadius: '1em'}} />
      </Box>
    </Box>
  );
};