import osmtogeojson from 'osmtogeojson';
import { osmSearch, osmSearchByBbox } from '../api/osmDataApi';

/**
 * fonctionnalité qui retourne donnée geojson de trottoir dessiné
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const drawnSidewalkGeo = async (id, startDate) => {
  const results = await osmSearch.searchByDraw(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir renseigné
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const describedSidewalkGeo = async (id, startDate) => {
  const results = await osmSearch.searchByRegisterAll(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir renseigné de 2 directions
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const describedBothGeoJson = async (id, startDate) => {
  const results = await osmSearch.searchByRegisterBoth(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir renseigné d'une direction
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const describedGeoJson = async (id, startDate) => {
  const results = await osmSearch.searchByRegister(id, startDate);
  const resultsGeoJson = osmtogeojson(results);
  return resultsGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir en théorie
 * @param {*} id osm ref de la région à traiter
 * @returns les données en forme geojson
 */

export const theoreticalSidewalkGeo = async (id) => {
  const results = await osmSearch.searchByTheory(id);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson des voies sans trottoir
 * @param {*} id osm ref de la région à traiter
 * @returns les données en forme geojson
 */

export const noSidewalkGeo = async (id) => {
  const results = await osmSearch.searchNoSidewalk(id);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du chemin piéton
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const walkPathGeo = async (id, startDate) => {
  const results = await osmSearch.searchPathWalk(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du chemin partagé entre piéton et cycliste
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const walkCyclePathGeo = async (id, startDate) => {
  const results = await osmSearch.searchPathWalkCycle(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du point des passages piétons
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const crossingPointGeo = async (id, startDate) => {
  const results = await osmSearch.searchCrossingPoint(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du path des passages piétons
 * @param {*} id osm ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const crossingPathGeo = async (id, startDate) => {
  const results = await osmSearch.searchCrossingPath(id, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};



/**
 * fonctionnalité qui retourne donnée geojson de trottoir dessiné
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const drawnSidewalkGeoByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchByDraw(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir renseigné
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const describedSidewalkGeoByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchByRegisterAll(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir renseigné de 2 directions
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const describedBothGeoJsonByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchByRegisterBoth(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir renseigné d'une direction
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const describedGeoJsonByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchByRegister(bbox, startDate);
  const resultsGeoJson = osmtogeojson(results);
  return resultsGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson de trottoir en théorie
 * @param {*} bbox ref de la région à traiter
 * @returns les données en forme geojson
 */

export const theoreticalSidewalkGeoByBbox = async (bbox) => {
  const results = await osmSearchByBbox.searchByTheory(bbox);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson des voies sans trottoir
 * @param {*} bbox ref de la région à traiter
 * @returns les données en forme geojson
 */

export const noSidewalkGeoByBbox = async (bbox) => {
  const results = await osmSearchByBbox.searchNoSidewalk(bbox);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du chemin piéton
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const walkPathGeoByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchPathWalk(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du chemin partagé entre piéton et cycliste
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const walkCyclePathGeoByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchPathWalkCycle(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du point des passages piétons
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const crossingPointGeoByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchCrossingPoint(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};

/**
 * fonctionnalité qui retourne donné geojson du path des passages piétons
 * @param {*} bbox ref de la région à traiter
 * @param {*} startDate date donnée
 * @returns les données en forme geojson
 */

export const crossingPathGeoByBbox = async (bbox, startDate) => {
  const results = await osmSearchByBbox.searchCrossingPath(bbox, startDate);
  const resultGeoJson = osmtogeojson(results);
  return resultGeoJson;
};