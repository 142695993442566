import React from 'react';

import { Grid } from '@mui/material';
import { IndicatorValue } from './IndicatorValue';

export const IndicatorInfos = ({
  drawnLength, 
  describedLength, 
  theoreticalLength,
  walkLength,
  walkCycleLength,
  pointCrossNumber,
  pathCrossNumber
}) => {
  return (
    <Grid container sx={{display: 'flex', width: '100%', justifyContent: 'center'}} spacing={2}>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={12}>
        <IndicatorValue 
          label='Trottoirs théoriques' 
          value={Math.round(theoreticalLength) + ' km'} 
          color='#A9A7A7'
        />
      </Grid>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={6}>
        <IndicatorValue 
          label='Trottoirs dessinés' 
          value={Math.round(drawnLength) + ' km'} 
          color='#42A5F5'
        />
      </Grid>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={6}>
        <IndicatorValue 
          label='Trottoirs renseignés' 
          value={Math.round(describedLength) + ' km'} 
          color='#FF7043' 
        />
      </Grid>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={6}>
        <IndicatorValue 
          label='Chemins piétons' 
          value={Math.round(walkLength) + ' km'} 
          color='#36CF11'
        />
      </Grid>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={6}>
        <IndicatorValue 
          label='Chemins partagés piétons / cyclistes' 
          value={Math.round(walkCycleLength) + ' km'} 
          color='#51ACC2'
        />
      </Grid>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={6}>
        <IndicatorValue 
          label='Passages piétons (point):' 
          value={Math.round(pointCrossNumber)} 
          color='#F9B357'
        />
      </Grid>
      <Grid sx={{display: 'flex', justifyContent: 'center', width: '100%'}} item xs={12} lg={6}>
        <IndicatorValue 
          label='Passages piétons (ligne):' 
          value={Math.round(pathCrossNumber)} 
          color='#A455FF'
        />
      </Grid>
    </Grid>
  );
};