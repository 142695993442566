import React from 'react';

import { Stack, Typography } from '@mui/material';

import { useResponsive } from '../hooks/useResponsive';

export const Footer = () => {
  const style = {
    container: {
      display: 'flex',
      width: '90%',
      paddingY: '0.5em'
    }
  };

  const {isMobile} = useResponsive();

  return (
    <Stack display="flex" direction="row" justifyContent='space-around' width='90%' alignItems='center'>
      <Stack sx={style.container} direction='column' spacing={1}>
        <Typography sx={{fontSize: '1em', color: '#8c8c8c'}}>
                  Un outil créé par
        </Typography>
        <a href="https://www.someware.fr" target="_blank" rel="noopener noreferrer">
          <img src = 'logo-someware.svg' style={{width: isMobile? 150: 200}} alt='Logo someware' />
        </a>
      </Stack>

      <a href="https://www.maptiler.com" target="_blank" rel="noopener noreferrer">
        <img src = 'maptiler-logo.svg' style={{width: isMobile? 150: 200}} alt='Logo maptiler' />
      </a>
    </Stack>
  );
};