import React from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { useResponsive } from '../hooks/useResponsive';

const GEOCODING_SERVICE_URL = 'https://api.maptiler.com/geocoding';
const GEOCODER_KEY = process.env.REACT_APP_MAPTILER_API_KEY;
  
const geocoder = {
  search: async (q) => {
    const url = `${GEOCODING_SERVICE_URL}/${q}.json?country=fr&language=fr&types=municipality,county&key=${GEOCODER_KEY}`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
};

export const getGeolocationPermission = () => {
  if (navigator.permissions) {
    return navigator.permissions.query({name: 'geolocation'});
  } else {
    return new Promise((resolve) => {
      resolve({
        state: 'granted',
      });
    });
  }
};

export const GeocoderInput = ({ locationFeature ,setLocationFeature }) => {
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const {isMobile} = useResponsive();

  const onType = async (evt, newValue) => {
    if (newValue !== null && newValue !== '') {
      const results = await geocoder.search(newValue);
      if (results !== undefined) {
        const {features} = results;
        const options = features.map(feature => ({'id': feature.id, 'label': feature.place_name, coord: feature.geometry.coordinates}));
        setOptions(options);
      } else {
        setOptions([]);
      }  
    } else {
      setOptions([]);
    }
  };

  const handleChangeInput = async (evt, newValue) => {
    if (newValue !== null || typeof newValue !== 'object') {
      const results = await geocoder.search(newValue.id);
      if (results !== undefined) {
        const {features} = results;
        setLocationFeature(features[0]);
      } else {
        return;
      }  
    } else {
      return;
    }
  };

  React.useEffect(() => {
    if (locationFeature) {
      setValue(`${locationFeature.place_name}, France`);
    }
  }, []);

  return (
    <Autocomplete
      id='address'
      sx={{
        display: 'flex',
        '& input': {
          fontSize: '1.1em',
        },
        '& input::placeholder': {
          fontSize: '1.1em',
          color: 'black',
          opacity: 0.6
        },
        '& button': {
          visibility: 'visible'
        },
        minWidth: isMobile ? 200 : 300,
        width: '100%'

      }}
      open={open}
      onOpen={() => {
        setTimeout(() => {
          setOpen(true);
        }, 500);
      }}
      onClose={() => setOpen(false)}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            {option.label}
          </li>
        );
      }}
      options={options}
      onInputChange={onType}
      onChange={handleChangeInput}
      value={value}
      freeSolo
      autoComplete
      includeInputInList
      filterSelectedOptions
      renderInput={(params) => {
        return (
          <TextField {...params}  
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
              }
            }}
            placeholder='Saisissez votre ville...' size="small"  />
        );
      }
      }
    />
  );
};