import React from 'react';

import { Layer, Source, useMap } from 'react-map-gl';


const layerStyleDrawn = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#42A5F5'
  },
  layout: {
    'line-cap': 'round'
  }
};
const layerStyleDescribed = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#FF7043'
  },
  layout: {
    'line-cap': 'round'
  }
};

const layerStyleTheoretical = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#A9A7A7'
  },
  layout: {
    'line-cap': 'round'
  }
};

const layerStyleNoSidewalk = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#FF0000'
  },
  layout: {
    'line-cap': 'round'
  }
};

const layerStyleWalkPath = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#36CF11'
  },
  layout: {
    'line-cap': 'round'
  }
};

const layerStyleWalkCyclePath = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#51ACC2'
  },
  layout: {
    'line-cap': 'round'
  }
};

const layerStyleCrossingPath = {
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 0.5,
      13, 3,
      20, 14
    ],
    'line-opacity': 1,
    'line-color': '#A455FF'
  },
  layout: {
    'line-cap': 'round'
  }
};

const layerStyleCrossingPoint = {
  type: 'circle',
  paint: {
    'circle-color': '#F9B357',
    'circle-opacity': 1,
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      12, 3,
      20, 5
    ]
  },
  // layout: {
  //     'icon-anchor': 'center',
  //     'icon-ignore-placement': true
  // }
};



export const MapLayers = ({
  theoreticalGeo, 
  noSidewalkGeo,
  drawnGeo, 
  describedGeo, 
  pathWalkGeo, 
  pathCycleWalkGeo, 
  crossGeo, 
  crossWayGeo, 
  layerConf
}) => {

  const {mymap} = useMap();
  const [update, setUpdate] = React.useState(0);


  const handleVisibilityLayer = () => {
    let map = mymap.getMap();
    for (const key in layerConf) {
      if (map.getLayer(key) !== undefined && mymap.getLayer !== null) {
        map.setLayoutProperty(key, 'visibility', layerConf[key].visibility === 'visible' ? 'visible' : 'none');
      }
    }
  };

  React.useEffect(() => {
    setUpdate(update + 1);
  }, [layerConf]);

  React.useEffect(() => {
    /**
         * handle visibility of layers when there is changes from selection of layers
         */
    if (mymap !== undefined && mymap !== null && update > 0) {
      handleVisibilityLayer();
    }
  }, [update]);

  React.useEffect(() => {
    /**
         * handle visibility of layers when there is changes in responsive
         */
    if (mymap !== undefined && mymap !== null) {
      mymap.getMap().once('idle', () => {
        handleVisibilityLayer();
      });
    }
  }, [mymap]);

  return (
    <>
      <Source id='theoretical' type='geojson' data={theoreticalGeo}>
        <Layer id='theoretical' {...layerStyleTheoretical} />
      </Source>
      <Source id='no_sidewalk' type='geojson' data={noSidewalkGeo}>
        <Layer id='no_sidewalk' {...layerStyleNoSidewalk} />
      </Source>
      <Source id='walk' type='geojson' data={pathWalkGeo}>
        <Layer id='walk' {...layerStyleWalkPath} />
      </Source> 
      <Source id='described' type='geojson' data={describedGeo}>
        <Layer id='described' {...layerStyleDescribed} />
      </Source> 
      <Source id='drawn' type='geojson' data={drawnGeo}>
        <Layer id='drawn' {...layerStyleDrawn} />
      </Source>
      <Source id='walk_cycle' type='geojson' data={pathCycleWalkGeo}>
        <Layer id='walk_cycle' {...layerStyleWalkCyclePath} />
      </Source> 
      <Source id='crossing_path' type='geojson' data={crossWayGeo}>
        <Layer id='crossing_path' {...layerStyleCrossingPath} />
      </Source> 
      <Source id='crossing_point' type='geojson' data={crossGeo}>
        <Layer id='crossing_point' {...layerStyleCrossingPoint} />
      </Source> 
    </>
  );
};