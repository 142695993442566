import React from 'react';

import { Circle } from '@mui/icons-material';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Popup } from 'react-map-gl';

import { getIdFromTitle, getTypeFromTitle } from '../utils/textUtils';
import { useResponsive } from '../hooks/useResponsive';

const OPEN_STREET_API = 'https://www.openstreetmap.org';

export const PopupLayer = ({showPopup, lngPopup, latPopup, handleFeature, feature, closePopup}) => {

  const {isMobile} = useResponsive();

  const renderTitleLayer = (id) => {
    switch (id) {
      case 'drawn':
        return 'Trottoir dessiné';
      case 'described':
        return 'Trottoir renseigné';
      case 'theoretical':
        return 'Trottoir théorique';
      case 'no_sidewalk':
        return 'Rue sans trottoir';
      case 'walk':
        return 'Chemin piéton';
      case 'walk_cycle':
        return 'Chemin partagé';
      case 'crossing_point':
        return 'Passage piéton (point)';
      case 'crossing_path':
        return 'Passage piéton (ligne)';
      default:
        return null;
    }
  };

  const renderColorCircle = (id) => {
    switch (id) {
      case 'drawn':
        return '#42A5F5';
      case 'described':
        return '#FF7043';
      case 'theoretical':
        return '#A9A7A7';
      case 'no_sidewalk':
        return '#FF0000';
      case 'walk':
        return '#36CF11';
      case 'walk_cycle':
        return '#51ACC2';
      case 'crossing_point':
        return '#F9B357';
      case 'crossing_path':
        return '#A455FF';
      default:
        return null;
    }
  };

  const style = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      paddingX: '1em',
      maxWidth: 300,
      // maxHeight: isDesktop ? null : 400,
      // overflowY: 'auto'
    }
  };
    

  const renderContent = () => {
    if (feature !== undefined && feature !== null) {
      const {properties} = feature;
      return (
        <Box sx={style.container}>
          <Stack sx={{display: 'flex'}} direction='column' spacing={isMobile ? 1 : 2}>
            <Box>
              <Typography sx={{fontSize: isMobile ? '.9em' : null, fontWeight: 600}}>
                {getTypeFromTitle(properties.id)}
                <Link href={`${OPEN_STREET_API}/${properties.id}`} rel='noopener noreferrer' target='_blank'>
                  {getIdFromTitle(properties.id)}
                </Link>
              </Typography>
            </Box>
            <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
              <Circle sx={{fontSize: '1em', marginRight: '1em', fill: renderColorCircle(feature.layer.id)}} />
              <Typography sx={{fontSize: isMobile ? '.9em' : null, paddingTop: isMobile ? '.2em' : null}}>{renderTitleLayer(feature.layer.id)}</Typography>
            </Box>
            {Object.keys(properties).filter(prop => prop !== 'id').map((prop, index) => (
              <Box key={index} sx={{display: 'flex'}}>
                <Typography sx={{fontSize: isMobile ? '.9em' : null, wordBreak: 'break-word'}}>
                  {prop} = {properties[prop]}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      );
    } else {
      return;
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
      {showPopup && (
        <Popup 
          latitude={latPopup} 
          longitude={lngPopup} 
          anchor="left" 
          closeOnClick={false}
          onClose={() => {
            handleFeature(undefined);
            closePopup();
          }}
          closeButton={true}
        >
          {feature && (
            renderContent()
          )}
        </Popup>
      )}
    </div>
  );
};