import React from 'react';

import { Box, Typography } from '@mui/material';
import { Chart, PieSeries, Title, Legend, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Animation, EventTracker, HoverState, Palette } from '@devexpress/dx-react-chart';
import { percentageCalcul } from '../utils/calculUtils';
import { useResponsive } from '../hooks/useResponsive';

export const RatioTheoryChart = ({value, totalTheory, color, legend, title}) => {
  const data = [
    {label: `${legend} ${percentageCalcul(value, totalTheory)}`, value: value, percentage: percentageCalcul(value, totalTheory)},
    {label: `Encore à décrire (théorique) ${percentageCalcul(totalTheory - value, totalTheory)}`, value: totalTheory - value, percentage: percentageCalcul(totalTheory - value, totalTheory)}];
    
  const {isMobile, isDesktop} = useResponsive();

  const [targetItem, setTargetItem] = React.useState(undefined);
    
  const legendRootStyle = {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  };
  const LegendRoot = props => (
    <Legend.Root {...props} style={legendRootStyle} />
  );
      
  const legendItemStyle = {
    flexDirection: 'column',
    marginLeft: '-10px',
    marginRight: '-10px',
  };
  const LegendItem = props => (
    <Legend.Item {...props} style={legendItemStyle} />
  );
      
  const legendLabelStyle = {
    whiteSpace: 'normal',
    textAlign: 'center',
  };
  const LegendLabel = props => (
    <Legend.Label {...props} style={legendLabelStyle} />
  );

  const TooltipContent = () => {
    return (
      <Box sx={{display: 'flex'}}>
        {data && targetItem && (data[targetItem.point].percentage)}
      </Box>  
    );
  };
    

  return (
    <Box sx={{display: 'flex'}}>
      <Chart width={220} data={data}>
        <Palette scheme={[color, '#A9A7A7']} />
        <PieSeries argumentField='label' valueField='value'/>
        <Title textComponent={() => (
          <Typography sx={{fontSize: isMobile ? '1em' :  '1.2em', textAlign: 'center', fontWeight: 700}}>
            {title}
          </Typography>
        )} />
        <Legend 
          position='bottom'
          rootComponent={LegendRoot}
          itemComponent={LegendItem}
          labelComponent={LegendLabel}
        />
        <EventTracker />
        {isDesktop && (<HoverState hover={targetItem} onHoverChange={targetItem => setTargetItem(targetItem)} />)}
        <Animation />
        {isDesktop && (
          <Tooltip  
            targetItem={targetItem}
            contentComponent={TooltipContent}
            onTargetItemChange={(targetItem) => setTargetItem(targetItem)}
          />
        )}
      </Chart>
    </Box>
  );
};