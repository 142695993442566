/* eslint-disable no-unused-vars */
import React from 'react';

import { Box, Stack, Grid } from '@mui/material';

import { IndicatorInfos } from '../components/IndicatorInfos';
import { RatioTheoryChart } from '../components/RatioTheoryChart';
import { RatioTypeChart } from '../components/RatioTypeChart';
import { useResponsive } from '../hooks/useResponsive';

export const IndicatorContainer = ({
  drawnLength, 
  describedLength, 
  theoreticalLength, 
  walkLength,
  walkCycleLength,
  valueShown,
  pointCrossNumber,
  pathCrossNumber,
  interval
}) => {
  const {isDesktop} = useResponsive();

  return (
    <Stack 
      sx={{
        display: 'flex', 
        width: '100%', 
        alignItems: 'center',
        overflowY: 'scroll', 
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          width: '0.2em',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '0.5em',
          backgroundColor: 'gray'
        },
      }} 
      direction='column' spacing={isDesktop ? 6 : 3}
    >
      <Box sx={{ display: 'flex', width: '90%', borderRadius: '1em'}}>
        <IndicatorInfos 
          drawnLength={drawnLength} 
          describedLength={describedLength} 
          theoreticalLength={theoreticalLength} 
          walkLength={walkLength}
          walkCycleLength={walkCycleLength}
          pointCrossNumber={pointCrossNumber}
          pathCrossNumber={pathCrossNumber}
        />
      </Box>
      {interval === 0 && !isDesktop && (<hr style={{display:'flex', width: '90%'}}/>)}
      {interval === 0 && (
        <Grid 
          container
          sx={{
            display: 'flex', 
            width: '100%', 
            justifyContent: 'center'
          }} 
          spacing={0.5}
        >
          <Grid item sx={{display: 'flex', justifyContent: 'center', width: '100%'}} xs={12} lg={6}>
            <RatioTheoryChart 
              value={drawnLength} 
              totalTheory={theoreticalLength} 
              color="#42A5F5" 
              legend="Dessinés" 
              title='Proportion de trottoirs dessinés' 
              valueShown={valueShown} 
            />
          </Grid>
          <Grid item sx={{display: 'flex', justifyContent: 'center', width: '100%'}} xs={12} lg={6}>
            <RatioTheoryChart 
              value={describedLength} 
              totalTheory={theoreticalLength} 
              color="#FF7043" 
              legend="Renseignés" 
              title='Proportion de trottoirs renseignés' 
              valueShown={valueShown} 
            />
          </Grid>
          <Grid item sx={{display: 'flex', justifyContent: 'center', width: '100%'}} xs={12} lg={6}>
            <RatioTypeChart 
              firstValue={drawnLength} 
              secondValue={describedLength} 
              valueShown={valueShown}
              title='Méthodes pour décrire les trottoirs'
              firstLabel='Trottoirs dessinés'
              secondLabel='Trottoirs renseignés'
              color1='#42A5F5'
              color2='#FF7043'
            />
          </Grid>
          <Grid item sx={{display: 'flex', justifyContent: 'center', width: '100%'}} xs={12} lg={6}>
            <RatioTypeChart 
              firstValue={pointCrossNumber} 
              secondValue={pathCrossNumber} 
              // valueShown={pathCrossNumber}
              title='Géométrie des passages piétons'
              firstLabel='Point'
              secondLabel='Ligne'
              color1='#F9B357'
              color2='#A455FF'
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};