import React from 'react';

import Map, { GeolocateControl, NavigationControl } from 'react-map-gl';
import maplibregl from '!maplibre-gl';  // ! is important here
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';
import 'maplibre-gl/dist/maplibre-gl.css';
import { bbox, center } from '@turf/turf';

import { MapLayers } from './MapLayers';
import { useResponsive } from '../hooks/useResponsive';
import { PopupLayer } from './PopupLayer';
import { FeatureSelectedHighlight } from './FeatureSelectedHighlight';



maplibregl.workerClass = maplibreglWorker;

const BASE_KEY = process.env.REACT_APP_MAPTILER_API_KEY;

export const MapApp = ({ 
  layerConf, 
  offsetMap, 
  drawnGeo, 
  describedGeo, 
  theoreticalGeo,
  noSidewalkGeo,
  pathWalkGeo,
  pathWalkCycleGeo,
  crossingGeo,
  crossingWayGeo,
  handleSetCalc
}) => {
  const {isMobile, isDesktop} = useResponsive();
  const [baseMaps] = React.useState(`https://api.maptiler.com/maps/streets-v2/style.json?key=${BASE_KEY}`);
  const mapRef = React.useRef();
  const geolocateControlRef = React.useRef();
  const [layerIsLoading] = React.useState(false);
  const [mapIsLoaded, setMapIsLoaded] = React.useState(false);
  const [listVisible, setListVisible] = React.useState([]);
  const [cursor, setCursor] = React.useState('auto');
  const [selectedFeature, setSelectedFeature] = React.useState(undefined);
  const [showPopup, setShowPopup] = React.useState(false);
  const [lngPopup, setLngPopup] = React.useState(null);
  const [latPopup, setLatPopup] = React.useState(null);


  const attributionControl = React.useRef(new maplibregl.AttributionControl());

  const handleSelectedFeature = feature => {
    setSelectedFeature(feature);
  };

  const handleMouseUp = e => {
    const features = mapRef.current?.getMap().queryRenderedFeatures(e.point, {
      layers: listVisible
    });
    if (features.length > 0) {
      if (showPopup) { 
        setShowPopup(false);
      }
      setLngPopup(e.lngLat.lng);
      setLatPopup(e.lngLat.lat);
      setSelectedFeature(features[0]);
      setShowPopup(true);
      const centerPoint = center(features[0]);
      if (centerPoint) {
        const coord = centerPoint.geometry.coordinates;
        mapRef.current?.flyTo({center: coord});
      } else {
        setSelectedFeature(undefined);
      }
    }
  };

  const onMouseEnter = React.useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = React.useCallback(() => setCursor('auto'), []);

  const handleVisibilityLayer = () => {
    let map = mapRef.current?.getMap();
    for (const key in layerConf) {
      if (map.getLayer(key) !== undefined && mapRef.current?.getLayer !== null) {
        map.setLayoutProperty(key, 'visibility', layerConf[key].visibility === 'visible' ? 'visible' : 'none');
      }
    }
  };

  const handleOnZoomSetCalc = () => {
    if(mapRef.current && mapRef.current.getZoom() > 12) {
      handleSetCalc(true);
    } else {
      handleSetCalc(false);
    }
  };

  React.useEffect(() => {
    if (theoreticalGeo && theoreticalGeo.features.length > 0) {
      const bounds = bbox(theoreticalGeo);
      if (bounds) {
        mapRef.current?.fitBounds(bounds, {
          maxZoom: 18,
          padding: ({bottom: isDesktop ? null : offsetMap + 50})
        });
      }
    } else {
      return;
    }
  }, [theoreticalGeo, isDesktop, offsetMap]);

  React.useEffect(() => {
    return () => {
      if (showPopup) {
        setShowPopup(false);
      }
      setSelectedFeature(undefined);
    };
  }, [theoreticalGeo]);

  React.useEffect(() => {
    mapRef.current?.once('load', () => setMapIsLoaded(true));
    if (mapRef.current !== undefined && mapRef.current !== null) {
      // Add attribution depending if responsive or not
      if (! mapRef.current.getMap().hasControl(attributionControl.current)) {
        mapRef.current?.getMap().addControl(attributionControl.current, isMobile ? 'bottom-left':'bottom-right');
      }
      // Disable rotation on phone
      mapRef.current?.getMap().touchZoomRotate.disableRotation();
    }
  }, [mapRef.current]);

  React.useEffect(() => {
    if (mapIsLoaded) {
      if (mapRef.current !== undefined && mapRef.current !== null) {
        // Add attribution depending if responsive or not
        if (! mapRef.current.getMap().hasControl(attributionControl.current)) {
          mapRef.current?.getMap().addControl(attributionControl.current, isMobile ? 'bottom-left':'bottom-right');
        }
        // Disable rotation on phone
        mapRef.current?.getMap().touchZoomRotate.disableRotation();
      }
    }
  }, [mapIsLoaded]);

  React.useEffect(() => {
    let layerInteractiveList = Object.keys(layerConf).filter(elem => layerConf[elem].visibility === 'visible');
    setListVisible([...layerInteractiveList]);
  }, [layerConf]);

  return (
    <Map 
      id='mymap'
      ref={mapRef}  
      mapLib={maplibregl}
      mapStyle={baseMaps}
      cursor={cursor}
      attributionControl={false}
      interactiveLayerIds={listVisible}
      onLoad={handleVisibilityLayer}
      onMouseUp={handleMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onZoom={handleOnZoomSetCalc}
      initialViewState={{
        latitude: 48.11005775107722,
        longitude: -1.6802165619654665,
        zoom: 8
      }}
      styleDiffing
    >
      <NavigationControl position='top-left' showCompass={true} />
      <GeolocateControl position='top-left' ref={geolocateControlRef} trackUserLocation={true} showUserHeading={true} positionOptions={{enableHighAccuracy: true}}/>
      {!layerIsLoading && drawnGeo && describedGeo && theoreticalGeo && pathWalkGeo && pathWalkCycleGeo && crossingGeo && crossingWayGeo && noSidewalkGeo && (
        <MapLayers 
          drawnGeo={drawnGeo} 
          describedGeo={describedGeo} 
          theoreticalGeo={theoreticalGeo}
          noSidewalkGeo={noSidewalkGeo}
          pathWalkGeo={pathWalkGeo}
          pathCycleWalkGeo={pathWalkCycleGeo}
          crossGeo={crossingGeo}
          crossWayGeo={crossingWayGeo}
          layerConf={layerConf}
        />
      )}
      <PopupLayer 
        showPopup={showPopup}
        lngPopup={lngPopup}
        latPopup={latPopup}
        feature={selectedFeature}
        closePopup={() => setShowPopup(false)}
        handleFeature={handleSelectedFeature}
      />
      { selectedFeature && (
        <FeatureSelectedHighlight feature={selectedFeature} />
      )}
    </Map>
  );
};