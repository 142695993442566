import React from 'react';

import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { IndicatorContainer } from './IndicatorContainer';
import { LayerListContainer } from './LayerListContainer';
import { useResponsive } from '../hooks/useResponsive';

export const InfoContainer = ({
  status,
  drawnLength,
  describedLength,
  theoreticalLength,
  walkLength,
  walkCycleLength,
  crossPointNumber,
  crossPathNumber,
  layerConfig,
  handleSetLayer,
  valueShown,
  interval, 
  handleInterval
}) => {
  const {isMobile, isDesktop} = useResponsive();
  const [content, setContent] = React.useState('indicator');

  const handleSelect = evt => {
    handleInterval(evt.target.value);
  };

  const style = {
    buttonContainer : {
      display: 'flex',
      width: '100%',
      justifyContent: isDesktop ? 'space-evenly' : 'center',
      position: isDesktop ? null : 'fixed',
      backgroundColor: '#FFFFFF',
      paddingY: '.5em',
      zIndex: 100,
      marginTop: isDesktop ? null : '-2px',
      alignItems: 'center',
    },
    groupContainer : {
      display: 'flex',
    },
    loadingContainer: {
      display: 'block', 
      paddingTop: isDesktop ? null : '5em',
    }, 
    contentContainer: {
      display: 'flex', 
      width: '100%',
      justifyContent: 'center',
      paddingTop: isDesktop ? null : '5em',
      overflow: 'scroll', 
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        width: '0.2em',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '0.5em',
        backgroundColor: 'gray'
      },
    }
  };

  const renderContent = () => {
    switch(content) {
      case 'indicator':
        return (
          <IndicatorContainer 
            drawnLength={drawnLength} 
            valueShown={valueShown}
            theoreticalLength={theoreticalLength} 
            describedLength={describedLength}
            walkLength={walkLength}
            walkCycleLength={walkCycleLength}
            pointCrossNumber={crossPointNumber}
            pathCrossNumber={crossPathNumber}
            interval={interval}
          />
        );
      case 'layer':
        return (
          <LayerListContainer 
            layerConfig={layerConfig}
            handleLayer={handleSetLayer}
          />
        );
      default:
        return;
    }
  };

  const renderResults = () => {
    if (status === 'loading') {
      return (
        <Box sx={style.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <Box sx={style.contentContainer}>
          {renderContent()}
        </Box>
      );
    }
  };

  return (
    <Stack sx={{display: 'flex', width: '100%', alignItems: 'center'}} direction='column' spacing={4}>
      <Stack sx={style.buttonContainer} direction={isMobile ? 'column' : 'row'} spacing={isDesktop ? 1 : 2}>
        <Stack sx={style.groupContainer} direction='row' spacing={1}>
          <Button sx={{width: '9em'}} size={isMobile ? 'small' : 'medium'} variant={content === 'indicator' ? 'contained' : 'outlined'} onClick={() => setContent('indicator')}>
                        Indicateurs
          </Button>
          <Button sx={{width: '9em'}} size={isMobile ? 'small' : 'medium'} variant={content === 'layer' ? 'contained' : 'outlined'} onClick={() => setContent('layer')}>
                        Couches
          </Button>
        </Stack>
        <Box sx={{ display: 'block', minWidth: isMobile ? 240 :  200 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-periode-label">Période</InputLabel>
            <Select
              labelId="select-periode-label"
              id="select-periode"
              value={interval}
              label="Période"
              onChange={handleSelect}
            >
              <MenuItem value={0}>Tout</MenuItem>
              <MenuItem value={1}>Depuis 1 semaine</MenuItem>
              <MenuItem value={2}>Depuis 6 mois</MenuItem>
              <MenuItem value={3}>Depuis 12 mois</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
      {renderResults()}
    </Stack>
  );
};