import React from 'react';

import { Box, Typography } from '@mui/material';
import { Chart, Legend, Title, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { Palette, PieSeries } from '@devexpress/dx-react-chart';
import { Animation, EventTracker, HoverState } from '@devexpress/dx-react-chart';
import { percentageCalcul } from '../utils/calculUtils';
import { useResponsive } from '../hooks/useResponsive';



export const RatioTypeChart = ({firstValue, secondValue, firstLabel, secondLabel, color1, color2, title}) => {
  const data = [
    {label: `${firstLabel} ${percentageCalcul(firstValue, firstValue + secondValue)}`, value: firstValue, percentage: percentageCalcul(firstValue, firstValue + secondValue)},
    {label: `${secondLabel} ${percentageCalcul(secondValue, firstValue + secondValue)}`, value: secondValue, percentage: percentageCalcul(secondValue, firstValue + secondValue)}
  ];

  const [targetItem, setTargetItem] = React.useState(undefined);
  const {isMobile, isDesktop} = useResponsive();
  const legendRootStyle = {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  };
  const LegendRoot = props => (
    <Legend.Root {...props} style={legendRootStyle} />
  );
      
  const legendItemStyle = {
    flexDirection: 'column',
    marginLeft: '-10px',
    marginRight: '-10px',
  };
  const LegendItem = props => (
    <Legend.Item {...props} style={legendItemStyle} />
  );
      
  const legendLabelStyle = {
    whiteSpace: 'normal',
    textAlign: 'center'
  };
  const LegendLabel = props => (
    <Legend.Label {...props} style={legendLabelStyle} />
  );
    
  //   const handleSelect =  ({targets}) => {
  //   const target = targets[0];
  //   if (target) {
  //     setSelection( selection[0] && compare(selection[0], target) ? [] : [target]);
  //     setTargetItem(target);
  //   }
  // };

  const TooltipContent = () => {
    return (
      <Box sx={{display: 'flex'}}>
        {data && targetItem && (data[targetItem.point].percentage)}
      </Box>  
    );
  };

  return (
    <Box sx={{display: 'flex'}}>
      <Chart width={220} data={data}>
        <Palette scheme={[color1, color2]} />
        <PieSeries argumentField='label' valueField='value' />
        <Title textComponent={() => (
          <Typography sx={{fontSize: isMobile ? '1em' :  '1.2em', textAlign: 'center', fontWeight: 700}}>
            {title}
          </Typography>
        )} />
        <Legend 
          position='bottom'
          rootComponent={LegendRoot}
          itemComponent={LegendItem}
          labelComponent={LegendLabel}
        />
        <EventTracker />
        {isDesktop && (
          <HoverState
            hover={targetItem}
            onHoverChange={targetItem => setTargetItem(targetItem)} />
        )}
        <Animation />
        {isDesktop && (
          <Tooltip
            targetItem={targetItem}
            contentComponent={TooltipContent}
            onTargetItemChange={(targetItem) => setTargetItem(targetItem)}
          />
        )}
      </Chart>
    </Box>
  );
};