import React from 'react';

import { Box, Typography } from '@mui/material';


export const IndicatorValue = ({label, value, color}) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', paddingX: '.5em', alignItems: 'center', minWidth: 250, justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', textAlign: 'center'}}>
        <Typography sx={{fontSize: '1em'}}>
          {label}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '1em'}}>
        <Typography sx={{fontSize: '1.2em', fontWeight: 700, color: color}}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};