import React from 'react';

import { IconButton } from '@mui/material';
import { useMap } from 'react-map-gl';
import { TravelExplore } from '@mui/icons-material';


export const CalcButtonContainer = ({reCalcActive, setStatus, fetchData}) => {
  const { mymap } = useMap();


  const style = {
    calcButton: {
      display: 'flex',
      width: 100,
      textTransform: 'none',
    },
    iconButton: {
      backgroundColor: '#1565c0',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#D3D3D3',
        color: '#000000'
      },
      '&:disabled': {
        backgroundColor: '#1565c0',
        color: '#FFFFFF',
        opacity: .5
      }
    }
  };

  const handleButtonFetchData = () => {
    if (mymap) {
      setStatus('loading');
      const bbox = mymap.getBounds();
      fetchData(bbox).catch(error => {
        console.log('error loading layer bbox updated', error);
      }).then(() => {
        setStatus('idle');
      });
    }
  };

  return (
    <IconButton 
      sx={style.iconButton} 
      size='small' 
      disabled={!reCalcActive}
      onClick={handleButtonFetchData}
    >
      <TravelExplore />
    </IconButton>
  );
};