const OSM_DATA_SERVICE_URL = 'https://overpass-api.de/api/interpreter';

export const osmSearch = {
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir 
     * comme précision d'une route avec comme valeur "both"
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByRegisterBoth: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[sidewalk~"^(both)$"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir 
     * comme précision d'une route avec comme valeur "left" ou "right"
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByRegister: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[sidewalk~"^(left|right)$"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir 
     * comme précision d'une route avec toutes les valeurs
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByRegisterAll: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[sidewalk~"^(left|right|both)$"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir
     * comme voie séparé
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByDraw: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[highway="footway"][footway="sidewalk"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie le trottoir en théorie
     * @param {*} osmID osm ref de la région à traiter
     * @returns les données en forme json
     */
  searchByTheory: async (osmID) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[highway~"^(living_street|residential|primary|secondary|tertiary)$"][sidewalk!="no"];);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
   * requête pour récupérer les données des voies sans trottoir
   * @param {*} osmID osm ref de la région à traiter
   * @returns les données en forme json
   */
  searchNoSidewalk: async (osmID) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[highway~"^(living_street|residential|primary|secondary|tertiary)$"][sidewalk="no"];);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les chemins piétons (séparés)
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchPathWalk: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[highway~"^(footway|pedestrian|living_street|path|track|steps)$"][footway!~"^(sidewalk|crossing)$"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les chemins partagés entre piétons et cyclistes
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchPathWalkCycle: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[highway~"^(cycleway)$"][foot~"^(yes|designated|permissive)$"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les passages piétons en points
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchCrossingPoint: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(node(area)[highway="crossing"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les passages en path
     * @param {*} osmID osm ref de la région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchCrossingPath: async (osmID, startDate) => {
    const areaID = 3600000000 + osmID;
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];area(${areaID});(way(area)[highway="footway"][footway="crossing"]${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }
};

const convertBbox = bbox => {
  if(bbox) {
    const {_ne, _sw} = bbox;
    const north = _ne.lat;
    const east = _ne.lng;
    const south = _sw.lat;
    const west = _sw.lng;
    return {south, west, north, east};
  } else {
    return undefined;
  }
};

export const osmSearchByBbox = {
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir 
     * comme précision d'une route avec comme valeur "both"
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByRegisterBoth: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[sidewalk~"^(both)$"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir 
     * comme précision d'une route avec comme valeur "left" ou "right"
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByRegister: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[sidewalk~"^(left|right)$"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir 
     * comme précision d'une route avec toutes les valeurs
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByRegisterAll: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[sidewalk~"^(left|right|both)$"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie par la méthode traiter le trottoir
     * comme voie séparé
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchByDraw: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[highway="footway"][footway="sidewalk"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie le trottoir en théorie
     * @param {*} bbox bounding box de région à traiter
     * @returns les données en forme json
     */
  searchByTheory: async (bbox) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[highway~"^(living_street|residential|primary|secondary|tertiary)$"][sidewalk!="no"](${south}, ${west}, ${north}, ${east}););(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
   * requête pour récupérer les données des voies sans trottoir
   * @param {*} bbox bounding box de région à traiter
   * @returns les données en forme json
   */
  searchNoSidewalk: async (bbox) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[highway~"^(living_street|residential|primary|secondary|tertiary)$"][sidewalk="no"](${south}, ${west}, ${north}, ${east}););(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les chemins piétons (séparés)
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchPathWalk: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[highway~"^(footway|pedestrian|living_street|path|track|steps)$"][footway!~"^(sidewalk|crossing)$"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les chemins partagés entre piétons et cyclistes
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchPathWalkCycle: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[highway~"^(cycleway)$"][foot~"^(yes|designated|permissive)$"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les passages piétons en points
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchCrossingPoint: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(node[highway="crossing"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  },
  /**
     * requête pour récupérer les données de région définie les passages en path
     * @param {*} bbox bounding box de région à traiter
     * @param {*} startDate date donnée
     * @returns les données en forme json
     */
  searchCrossingPath: async (bbox, startDate) => {
    const {south, west, north, east} = convertBbox(bbox); 
    const url = `${OSM_DATA_SERVICE_URL}?data=[out:json];(way[highway="footway"][footway="crossing"](${south}, ${west}, ${north}, ${east})${startDate ? `(newer:"${startDate}")`:''};);(._;>;);out;`;
    const results = fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }
};

