export const getTypeFromTitle = title => {
  const typeTitle  = title.split('/')[0];
  const newTitle = typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1) + ' ';
  return newTitle;
};

export const getIdFromTitle = title => {
  const typeTitle  = title.split('/')[1];
  return typeTitle;
};
