import React from 'react';

import {Source, Layer} from 'react-map-gl';



export const FeatureSelectedHighlight = ({feature}) => {
  const hightlightStyle = {
    type: 'line',
    paint: {
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10, 1,
        13, 5,
        20, 15
      ],
      'line-opacity': 1,
      'line-color': 'red',
    },
    layout: {
      'line-cap': 'round'
    }
  };
  const hightlightPoint = {
    type: 'circle',
    paint: {
      'circle-color': 'red',
      'circle-opacity': 1,
      'circle-stroke-width': 1,
      'circle-stroke-color':'#FFFFFF'
    },
  };

  const renderHighlight = feature => {
    if (feature) {
      const {_geometry} = feature;
      if (_geometry.type === 'LineString') {
        return (
          <Source key='selected_feature_layer_line' id='selected_feature_layer_line' type='geojson' data={feature}>
            <Layer key='selected_feature_layer_line' id='selected_feature_layer_line' {...hightlightStyle} />
          </Source>
        );
      } else {
        return (
          <Source key='selected_feature_layer_point' id='selected_feature_layer_point' type='geojson' data={feature}>
            <Layer key='selected_feature_layer_point' id='selected_feature_layer_point' {...hightlightPoint} />
          </Source>
        );
      }
    } else {
      return;
    }
  };
  return (
    <>
      {renderHighlight(feature)}
    </>
  );
};