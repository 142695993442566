import React from 'react';

import { Box } from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';


export const BottomSheetInfo = ({children, status, handleOffSet, handleIsOffset}) => {
  const sheetInfoRef = React.useRef();
  const [block, setBlock] = React.useState(false);

  React.useEffect(() => {
    if (status === 'loading') {
      setBlock(true);
      handleIsOffset();
      sheetInfoRef.current?.snapTo(({maxHeight}) => maxHeight / 2);
    } else {
      setBlock(false);
      if (sheetInfoRef.current) {
        handleOffSet(sheetInfoRef.current.height);
      }
    }
  }, [status, sheetInfoRef.current]);

  return (
    <BottomSheet 
      open={true} 
      ref={sheetInfoRef}
      blocking={block} 
      defaultSnap={({snapPoints}) => Math.max(...snapPoints)}
      snapPoints={({ headerHeight, maxHeight}) => [headerHeight, maxHeight / 2]}
    >
      <Box sx={{display: 'flex', width: '100%', paddingBottom: '.5em', alignItems: 'center'}}>
        {children}
      </Box>
    </BottomSheet>
  );
};