/* eslint-disable no-unused-vars */
import React from 'react';

import { Box, Button, Stack, Tooltip } from '@mui/material';
import { MapProvider } from 'react-map-gl';

import { MapApp } from '../components/Map';
import { InfoContainer } from './InfoContainer';
import { GeocoderInput } from '../components/GeocoderInput';
import { useResponsive } from '../hooks/useResponsive';
import { BottomSheetInfo } from '../components/BottomSheetInfo';
import { crossingPathGeo, crossingPathGeoByBbox, crossingPointGeo, crossingPointGeoByBbox, describedBothGeoJson, describedBothGeoJsonByBbox, describedGeoJson, describedGeoJsonByBbox, drawnSidewalkGeo, drawnSidewalkGeoByBbox, noSidewalkGeo, noSidewalkGeoByBbox, theoreticalSidewalkGeo, theoreticalSidewalkGeoByBbox, walkCyclePathGeo, walkCyclePathGeoByBbox, walkPathGeo, walkPathGeoByBbox } from '../utils/geoUtils';
import { concatGeoJson } from '../utils/calculUtils';
import { lengthSideWalk } from '../utils/lengthUtils';
import { date12monthsBefore, date1weekBefore, date6monthsBefore } from '../utils/dateUtils';
import { CalcButtonContainer } from './CalcButtonContainer';


const layerConf = {
  theoretical: { 
    visibility: 'none',
    color: '#A9A7A7'
  },
  no_sidewalk: {
    visibility: 'none',
    color: '#FF0000'
  },
  walk: {
    visibility: 'none',
    color: '#36CF11'
  },
  described: {
    visibility: 'visible',
    color: '#FF7043'
  }, 
  drawn: {
    visibility: 'visible',
    color: '#42A5F5'
  }, 
  walk_cycle: {
    visibility: 'none',
    color: '#51ACC2'
  },
  crossing_path: {
    visibility: 'none',
    color: '#A455FF'
  },
  crossing_point: {
    visibility: 'none',
    color: '#F9B357'
  }
};


export const ExploreContainer = ({locationFeature, handleLocationFeature}) => {

  const {isMobile, isDesktop} = useResponsive();

  const [status, setStatus] = React.useState('idle');
   
  const [idLocation, setIdLocation] = React.useState(undefined);
  const [layerConfig, setLayerConfig] = React.useState(layerConf);
  const [valueIsShown, setValueIsShown] = React.useState(true);
  const [offset, setOffset] = React.useState(100);
  const [drawnGeo, setDrawnGeo] = React.useState(undefined);
  const [describedGeo, setDescribedGeo] = React.useState(undefined);
  const [theoreticalGeo, setTheoreticalGeo] = React.useState(undefined);
  const [noSideWalkGeo, setNoSideWalkGeo] = React.useState(undefined);
  const [pathWalkGeo, setPathWalkGeo] = React.useState(undefined);
  const [pathWalkCycleGeo, setPathWalkCycleGeo] = React.useState(undefined);
  const [crossingGeo, setCrossingGeo] = React.useState(undefined);
  const [crossingWayGeo, setCrossingWayGeo] = React.useState(undefined);
  const [drawnLength, setLengthDrawn] = React.useState(0);
  const [describedLength, setLengthDescribed] = React.useState(0);
  const [theoreticalLength, setLengthTheoretical] = React.useState(0);
  const [walkLength, setWalkLength] = React.useState(0);
  const [walkCycleLength, setWalkCycleLength] = React.useState(0);
  const [crossPointNumber, setCrossPointNumber] = React.useState(0);
  const [crossPathNumber, setCrossPathNumber] = React.useState(0);
  const [isOffset, setIsOffset] = React.useState(false);
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  const [interval, setInterval] = React.useState(0);
  const [reCalcActive, setReCalcActive] = React.useState(false);

  const handleStatePage = value => {
    setStatus(value);
  };

  const handleInterval = value => {
    setInterval(value);
  };

  const fetchGeoData = async (osmID) => {
    if (osmID) {
      setIdLocation(osmID);
      const drawnGeoJson = await drawnSidewalkGeo(osmID, startDate);
      setDrawnGeo(drawnGeoJson);
      setLengthDrawn(lengthSideWalk(drawnGeoJson));
      const describedGeo = await describedGeoJson(osmID, startDate);
      const describedBothGeo = await describedBothGeoJson(osmID, startDate);
      setLengthDescribed(lengthSideWalk(describedGeo) + lengthSideWalk(describedBothGeo) * 2);
      setDescribedGeo(concatGeoJson(describedGeo, describedBothGeo));
      const theoreticalGeoJson = await theoreticalSidewalkGeo(osmID, startDate);
      setTheoreticalGeo(theoreticalGeoJson);
      setLengthTheoretical(lengthSideWalk(theoreticalGeoJson) * 2);
      const noSidewalkGeoJson = await noSidewalkGeo(osmID);
      setNoSideWalkGeo(noSidewalkGeoJson);
      const pathWalkGeo = await walkPathGeo(osmID, startDate);
      setPathWalkGeo(pathWalkGeo);
      setWalkLength(lengthSideWalk(pathWalkGeo));
      const pathCycleWalkGeo = await walkCyclePathGeo(osmID, startDate);
      setPathWalkCycleGeo(pathCycleWalkGeo);
      setWalkCycleLength(lengthSideWalk(pathCycleWalkGeo));
      const crossingGeo = await crossingPointGeo(osmID, startDate);
      setCrossingGeo(crossingGeo);
      setCrossPointNumber(crossingGeo.features.length);
      const crossingWayGeo = await crossingPathGeo(osmID, startDate);
      setCrossingWayGeo(crossingWayGeo);
      setCrossPathNumber(crossingWayGeo.features.length);
    }
  };

  const fetchGeoDataByBbox = async (bbox) => {
    if (bbox) {
      setIdLocation(undefined);
      const drawnGeoJsonByBbox = await drawnSidewalkGeoByBbox(bbox, startDate);
      setDrawnGeo(drawnGeoJsonByBbox);
      setLengthDrawn(lengthSideWalk(drawnGeoJsonByBbox));
      const describedGeoByBbox = await describedGeoJsonByBbox(bbox, startDate);
      const describedBothGeoByBbox = await describedBothGeoJsonByBbox(bbox, startDate);
      setLengthDescribed(lengthSideWalk(describedGeoByBbox) + lengthSideWalk(describedBothGeoByBbox) * 2);
      setDescribedGeo(concatGeoJson(describedGeoByBbox, describedBothGeoByBbox));
      const theoreticalGeoJsonByBbox = await theoreticalSidewalkGeoByBbox(bbox, startDate);
      setTheoreticalGeo(theoreticalGeoJsonByBbox);
      setLengthTheoretical(lengthSideWalk(theoreticalGeoJsonByBbox) * 2);
      const noSidewalkGeoJsonByBbox = await noSidewalkGeoByBbox(bbox);
      setNoSideWalkGeo(noSidewalkGeoJsonByBbox);
      const pathWalkGeoByBbox = await walkPathGeoByBbox(bbox, startDate);
      setPathWalkGeo(pathWalkGeoByBbox);
      setWalkLength(lengthSideWalk(pathWalkGeoByBbox));
      const pathCycleWalkGeoByBbox = await walkCyclePathGeoByBbox(bbox, startDate);
      setPathWalkCycleGeo(pathCycleWalkGeoByBbox);
      setWalkCycleLength(lengthSideWalk(pathCycleWalkGeoByBbox));
      const crossingGeoByBbox = await crossingPointGeoByBbox(bbox, startDate);
      setCrossingGeo(crossingGeoByBbox);
      setCrossPointNumber(crossingGeoByBbox.features.length);
      const crossingWayGeoByBbox = await crossingPathGeoByBbox(bbox, startDate);
      setCrossingWayGeo(crossingWayGeoByBbox);
      setCrossPathNumber(crossingWayGeoByBbox.features.length);
    } else {
      console.log('error fetch data by bbox');
      return;
    }
  };

  const handleSetLayerConfig = elem => {
    if (layerConfig[elem].visibility === 'visible') {
      layerConfig[elem].visibility = 'none';
    } else {
      layerConfig[elem].visibility = 'visible';
    }
    setLayerConfig({...layerConfig});
  };

  const handleValueShown = value => {
    if(value === 'hide') {
      setValueIsShown(false);
    } else {
      setValueIsShown(true);
    }
  };

  const handleOffset = value => {
    setOffset(value);
  };

  const handleIsOffset = () => {
    setIsOffset(true);
  };

  const handleNotOffset = () => {
    setIsOffset(false);
  };

  const handleStartDate = (value) => {
    switch (value) {
      case 0:
        setStartDate(undefined);
        break;
      case 1:
        setStartDate(date1weekBefore);
        break;
      case 2:
        setStartDate(date6monthsBefore);
        break;
      case 3:
        setStartDate(date12monthsBefore);
        break;
      default:
        setStartDate(undefined);
        break;
    }
  };

  const handleSetCalc = value => {
    setReCalcActive(value);
  };

  React.useEffect(() => {
    if (locationFeature) {
      setStatus('loading');
      const osmID = parseInt(locationFeature.properties.ref.split(':r')[1]);
      fetchGeoData(osmID).catch(error => {
        console.log('error loading layer updated', error);
      }).then(() => {
        setStatus('idle');
      });
    } else {
      setStatus('loading');
      fetchGeoData(54517).catch(error => {
        console.log('error loading layer 1st mount', error);
      }).then(() => {
        setStatus('idle');
      });
    }
    return () => {
      setDrawnGeo(undefined);
      setDescribedGeo(undefined);
      setPathWalkGeo(undefined);
      setPathWalkCycleGeo(undefined);
      setCrossingGeo(undefined);
      setCrossingWayGeo(undefined);
    };
  }, [locationFeature, startDate]);

  React.useEffect(() => {
    handleStartDate(interval);
  }, [interval]);

  const style = {
    container: {
      display: 'flex',
      width: '100%',
      backgroundColor: '#FFFFFF',
      height: isDesktop ? 'calc( 100vh - 4.29em)' : '100vh',
    }, 
    mapContainer: {
      display: 'flex',
      width: isDesktop ? '65%' : '100%' ,
      height: '100%',
      minWidth: 300,
      minHeight: 'calc( 100vh - 4.29em)'
    },
    infoContainer: {
      display: 'flex',
      backgroundColor: '#FFFFFF',
      paddingY: '1em',
      width: isMobile ? '100%' : '35%',
      minWidth: 350
    },
    functionContainer: {
      display: 'flex',
      position: 'absolute',
      zIndex: 1000,
      left: '25%',
      marginTop: '1em',
      borderRadius: '1em',
      alignItems: 'center'
    },
    geocoderContainer: {
      display: 'flex',
      height: '100%',
      minWidth: isMobile ? 200 : 300,   
      backgroundColor: '#FFFFFF',
      borderRadius: '1em'
    },
    buttonContainer: {
      display: 'flex',
      height: '100%',
    }
  };
  return (
    <MapProvider>
      <Box sx={style.container}>
        <Box sx={style.mapContainer}>
          <Box sx={{display: 'flex', width: '100%'}}>
            <Stack sx={style.functionContainer} spacing={2} direction='row'>
              <Box sx={style.geocoderContainer}> 
                <GeocoderInput 
                  locationFeature={locationFeature} 
                  setLocationFeature={handleLocationFeature} 
                />
              </Box>
              <Tooltip title={reCalcActive ? 'Obtenir les données piétonnes sur cette zone' : 'Zoomer pour utiliser cette fonctionnalité'} arrow placement='bottom-start'>
                <Box sx={style.buttonContainer}>
                  <CalcButtonContainer 
                    reCalcActive={reCalcActive} 
                    fetchData={fetchGeoDataByBbox} 
                    setStatus={setStatus} 
                  />
                </Box>
              </Tooltip>
            </Stack>
            <MapApp 
              idLocation={idLocation} 
              layerConf={layerConfig} 
              handleNotOffset={handleNotOffset}
              status={status} 
              offsetMap={offset}
              drawnGeo={drawnGeo}
              describedGeo={describedGeo}
              theoreticalGeo={theoreticalGeo}
              noSidewalkGeo={noSideWalkGeo}
              pathWalkGeo={pathWalkGeo}
              pathWalkCycleGeo={pathWalkCycleGeo}
              crossingGeo={crossingGeo}
              crossingWayGeo={crossingWayGeo}
              handleSetCalc={handleSetCalc}
            />
          </Box>
        </Box>
        {isDesktop ? (
          <Box sx={style.infoContainer}>
            <InfoContainer 
              idLocation={idLocation}
              layerConfig={layerConfig} 
              status={status}
              drawnLength={drawnLength}
              describedLength={describedLength}
              theoreticalLength={theoreticalLength}
              walkLength={walkLength}
              walkCycleLength={walkCycleLength}
              crossPointNumber={crossPointNumber}
              crossPathNumber={crossPathNumber}
              handleSetLayer={handleSetLayerConfig}
              handleStatus={handleStatePage}
              valueShown={valueIsShown}
              interval={interval}
              handleInterval={handleInterval}
            />
          </Box>
        ) : (
          <BottomSheetInfo 
            status={status} 
            handleIsOffset={handleIsOffset}
            handleValue={handleValueShown} 
            handleOffSet={handleOffset}
          >
            <InfoContainer 
              status={status}
              idLocation={idLocation}
              layerConfig={layerConfig} 
              drawnLength={drawnLength}
              describedLength={describedLength}
              theoreticalLength={theoreticalLength}
              walkLength={walkLength}
              walkCycleLength={walkCycleLength}
              crossPointNumber={crossPointNumber}
              crossPathNumber={crossPathNumber}
              handleSetLayer={handleSetLayerConfig}
              handleStatus={handleStatePage}
              valueShown={valueIsShown}
              interval={interval}
              handleInterval={handleInterval}
            />
          </BottomSheetInfo>
        )
        }             
      </Box>
    </MapProvider>
  );
};