export const percentageCalcul = (value, valueTotal) => {
  return `${(( value / valueTotal ) * 100).toFixed(1)}%`;
};

export const numberFeatureGeo = feature => {
  if (feature) {
    return feature.length;
  } else {
    return 0;
  }
};

export const concatGeoJson = (g1, g2) => {
  return {
    'type' : 'FeatureCollection',
    'features': g1.features.concat(g2.features)
  };
};