import React from 'react';

import { Stack } from '@mui/material';
import { CheckboxLayer } from '../components/CheckboxLayer';
import { useResponsive } from '../hooks/useResponsive';

export const LayerListContainer = ({
  layerConfig,
  handleLayer,
}) => {
    
  const {isDesktop} = useResponsive();
    
  const renderExplain = id => {
    switch (id) {
      case 'drawn':
        return 'Trottoirs qui sont dessinés dans OSM, au même titre que les routes ou les chemins. Ce sont les ways avec les attributs highway=footway et footway=sidewalk.';
      case 'described':
        return "Trottoirs renseignés au niveau du tronçon de route (axe central) à l'aide de l'attribut sidewalk=both/left/right.";
      case 'theoretical': 
        return 'Rues utilisées pour calculer les trottoirs théoriques. Ce sont les ways avec les attributs highway=living_street/residential/primary/secondary/tertiary) et footway!=no.';
      case 'no_sidewalk':
        return 'Rues sans trottoir. Ce sont les chemins avec les attributs highway=living_street/residential/primary/secondary/tertiary) et sidewalk=no.';
      case 'walk':
        return 'Chemins prévus pour les piétons, ou sur lesquels les piétons sont prioritaires et peuvent circuler sur toute la largeur de la chaussée. Ce sont les ways avec les attributs highway=footway/pedestrian/living_street/path/track/steps et footway!=sidewalk/crossing.';
      case 'walk_cycle':
        return 'Chemins partagés entre piétons et les cyclistes, définis par highway=cycleway et foot=yes/designated/permissive.';
      case 'crossing_point':
        return "Passages piétons représentés par des nodes avec l'attribut highway=crossing.";
      case 'crossing_path':
        return "Passages piétons représentés par des ways avec l'attribut highway=footway et footway=crossing";
      default: 
        return '';
    }
  };

  const renderLabel = id => {
    switch (id) {
      case 'drawn':
        return 'Trottoirs dessinés';
      case 'described':
        return 'Trottoirs renseignés';
      case 'theoretical': 
        return 'Rues avec trottoirs théoriques';
      case 'no_sidewalk':
        return 'Rues sans trottoir';
      case 'walk':
        return 'Chemins piétons';
      case 'walk_cycle':
        return 'Chemins partagés piétons / cyclistes';
      case 'crossing_point':
        return 'Passages piétons de type point';
      case 'crossing_path':
        return 'Passages piétons de type ligne';
      default: 
        return '';
    }
  };

  return (
    <Stack sx={{display: 'flex', paddingX: isDesktop ? null : '.3em', width: '95%'}} spacing={2} direction='column'>
      {Object.keys(layerConfig).map(elem => (
        <CheckboxLayer 
          key={elem} 
          id={elem} 
          value={layerConfig[elem].visibility === 'visible'}
          label={renderLabel(elem)}
          type={elem.includes('point') ? 'point' : 'line'}
          color={layerConfig[elem].color}
          handleLayer={handleLayer}
          explain={renderExplain(elem)}
        />
      ))}
    </Stack>
  );
};