import React from 'react';

import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, Button, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '../hooks/useResponsive';

const PUBLIC_URL = process.env.PUBLIC_URL || '';


export const NavBar = () => {

  const pages = [{label: 'Accueil', path: '/'}, {label: 'Explorer', path: '/explore'}];
  const publicUrl = path => {
    return `${PUBLIC_URL}${path}`;
  };
  const navigate = useNavigate();
  const {isMobile} = useResponsive();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleChangeRoute = path => {
    navigate(publicUrl(path));
  };


  
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '90%'}} disableGutters>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <MapIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href={publicUrl('/')}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1,                
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              OSMarche
            </Typography>
          </Box>
          <MapIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              fontSize: '1.5em',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            OSMarche
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={() => handleChangeRoute(page.path)}
                sx={{ my: 2, color: 'white', display: 'block', '&: hover': {
                  textDecoration: 'underline'
                } }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu-page"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem 
                  key={index} 
                  onClick={() => {
                    handleChangeRoute(page.path);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};